// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//

// Variables
$primary: #4AA851;
$success: #61BF23;

// Colors
$body-color:                                                #677788 !default;
$headings-color:                                            #1E2022 !default;



// Border Radius
$border-radius:                                             0rem !default;
