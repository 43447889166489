.navbar-top-bg {
  background: #F9FBFD;
  background-color: #F9FBFD;
}
.navbar-shadow {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.20);

}

.menu-item {
  @extend .pl-2;



}


.nav-link {
  color: $dark;

  font-size: 1.2em;
  font-weight: 700 !important;

  &:hover,
  &:focus {
  color:$primary !important;


  }

  .active {
    color:$primary !important;

  }
}

.active {
  color:$primary !important;

}
#menu-top-nav {

  .menu-item {

.telnr,
  a {
    @extend .text-muted;
    font-size: .9em;

    &:hover,
    &:focus {
    color:$primary !important;


    }
}
  }
}

  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap
/*
  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
    body {
        padding-top:60px;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {

    body {
        padding-top:90px;
    }
   }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    body {
        padding-top:100px;
    }


  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

    body {
        padding-top:110px;
    }


   }
*/


@include media-breakpoint-down(xs) {  }
@include media-breakpoint-down(sm) {

  .page-header h1,
  .page-header h2 {
    font-size: 1.28em !important;
  }

 }
@include media-breakpoint-down(md) {

  .page-header h1,
  .page-header h2 {
    font-size: 1.48em !important;
  }

  }
@include media-breakpoint-down(lg) {  }



.page-header .breadcrumbs a {
  @extend .text-white;
}

.page-header .breadcrumbs .current-item {
  @extend .text-white-70;
}
