.video-player-icon {
background: rgba(247, 250, 255, 0.6) !important;

width: 3.25rem !important;
height: 3.25rem !important;
}

.bg-light {

  background: #F3F5F6 !important;
}


.bg-primary .breadcrumbs a.post {
  color:#fff !important;

}
